'use client'
import { Page } from "../../components/shared/Page"
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure,
    Progress,
    ButtonGroup,
    Flex,
    GridItem,
    Select,
    SimpleGrid,
    InputLeftAddon,
    Textarea,
    FormHelperText,
    useToast
} from '@chakra-ui/react';



import {
    

} from '@chakra-ui/react'

import { Logo } from "../../components/shared/Logo";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { OAuthButtonGroup } from "../../components/shared/OAuthButtonGroup";
import { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignInWithEmailAndPassword } from "../../hooks/auth/useSignInWithEmailAndPassword";
import { useSignUpWithEmailAndPassword } from "../../hooks/auth/useSignUpWithEmailAndPassword";
import { signInWithOAuth } from "../../api/authApi";
import { useSignInWithMagicLink } from "../../hooks/auth/useSignInWithMagicLink";

type FormValues = {
    email: string;
    password: string;
    name: string;
};

const Signup = () => {
    const formBackground = useColorModeValue('gray.100', 'gray.700');
    const [authButtonState, setAuthButtonState] = useState(true);
    const { isOpen, onToggle } = useDisclosure()
    let navigate = useNavigate();
    let location = useLocation();
    const toast = useToast();
    const [step, setStep] = useState(1)
    const [progress, setProgress] = useState(33.33)
    const signIn = useSignInWithEmailAndPassword();
    const signUp = useSignUpWithEmailAndPassword();
    const signWithMagicLink = useSignInWithMagicLink()

    let from = (location as any).state?.from?.pathname || "/";

    const onClickReveal = () => {
        onToggle()
    }

    const { handleSubmit, register, formState: { errors, isValid } } = useForm<FormValues>({
        // defaultValues: {
        //     email: "janedane@gmail.com",
        //     password: "123456"
        // }
    });

    const { register: register2, formState: { errors: errors2, isValid: isValid2 }, handleSubmit: handleSubmit2 } = useForm<FormValues>({
        mode: "onBlur",
    });

    const handleSignInWithMagicLink: SubmitHandler<FormValues> = (values) => {
        signWithMagicLink.mutate(values, {
            onSuccess: () => {
                toast({
                    title: 'Account confirmed.',
                    position: 'top',
                    description: 'Check your email for the login link',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
                navigate(from, { replace: true });
            },
            onError: (err) => {
                toast({
                    description: `${err}`,
                    status: "error"
                });
            }
        });
    };

    const handleSignIn: SubmitHandler<FormValues> = (values) => {
        console.log(from)
        signIn.mutate(values, {
            onSuccess: () => {
                console.log(from)
                navigate(from, { replace: true });
            },
            onError: (err) => {
                toast({
                    description: `${err}`,
                    status: "error"
                });
            }
        });
    };

    const handleSignUp: SubmitHandler<FormValues> = (values) => {
        console.log(from)
        signUp.mutate(values, {
            onSuccess: () => {
                console.log(from)
                navigate(from, { replace: true });
            },
            onError: (err) => {
                toast({
                    description: `${err}`,
                    status: "error"
                });
            }
        });
    };

    const signInWithGithub = async () => {
        try {
            signInWithOAuth()
        } catch (error: any) {
            toast({
                title: 'Error',
                position: 'top',
                description: error.error_description || error.message,
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    const handleCallBack = useCallback(
        (stringFromChild: string) => {
            switch (stringFromChild) {
                case 'Google':
                    console.log('Login with google...')
                    break;
                case 'GitHub':
                    signInWithGithub();
                    break;
                case 'Twitter':
                    console.log('Login with twitter...')
                    break;
                default:
                    break;
            }
        },
        []
    );

    const Form1 = () => {
        const [show, setShow] = useState(false)
        const handleClick = () => setShow(!show)
        return (
            <>
                <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
                    User Registration
                </Heading>
                <Flex>
                    <FormControl mr="5%">
                        <FormLabel htmlFor="first-name" fontWeight={'normal'}>
                            First name
                        </FormLabel>
                        <Input id="first-name" placeholder="First name" />
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="last-name" fontWeight={'normal'}>
                            Last name
                        </FormLabel>
                        <Input id="last-name" placeholder="First name" />
                    </FormControl>
                </Flex>
                <FormControl mt="2%">
                    <FormLabel htmlFor="email" fontWeight={'normal'}>
                        Email address
                    </FormLabel>
                    <Input id="email" type="email" />
                    <FormHelperText>We&apos;ll never share your email.</FormHelperText>
                </FormControl>

                <FormControl>
                    <FormLabel htmlFor="password" fontWeight={'normal'} mt="2%">
                        Password
                    </FormLabel>
                    <InputGroup size="md">
                        <Input
                            pr="4.5rem"
                            type={show ? 'text' : 'password'}
                            placeholder="Enter password"
                        />
                        <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={handleClick}>
                                {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
            </>
        )
    }

    const Form2 = () => {
        return (
            <>
                <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
                    User Details
                </Heading>
                <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                        htmlFor="country"
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                            color: 'gray.50',
                        }}>
                        Country / Region
                    </FormLabel>
                    <Select
                        id="country"
                        name="country"
                        autoComplete="country"
                        placeholder="Select option"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md">
                        <option>United States</option>
                        <option>Canada</option>
                        <option>Mexico</option>
                    </Select>
                </FormControl>

                <FormControl as={GridItem} colSpan={6}>
                    <FormLabel
                        htmlFor="street_address"
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                            color: 'gray.50',
                        }}
                        mt="2%">
                        Street address
                    </FormLabel>
                    <Input
                        type="text"
                        name="street_address"
                        id="street_address"
                        autoComplete="street-address"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md"
                    />
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 6, null, 2]}>
                    <FormLabel
                        htmlFor="city"
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                            color: 'gray.50',
                        }}
                        mt="2%">
                        City
                    </FormLabel>
                    <Input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="city"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md"
                    />
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
                    <FormLabel
                        htmlFor="state"
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                            color: 'gray.50',
                        }}
                        mt="2%">
                        State / Province
                    </FormLabel>
                    <Input
                        type="text"
                        name="state"
                        id="state"
                        autoComplete="state"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md"
                    />
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
                    <FormLabel
                        htmlFor="postal_code"
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                            color: 'gray.50',
                        }}
                        mt="2%">
                        ZIP / Postal
                    </FormLabel>
                    <Input
                        type="text"
                        name="postal_code"
                        id="postal_code"
                        autoComplete="postal-code"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md"
                    />
                </FormControl>
            </>
        )
    }

    const Form3 = () => {
        return (
            <>
                <Heading w="100%" textAlign={'center'} fontWeight="normal">
                    Social Handles
                </Heading>
                <SimpleGrid columns={1} spacing={6}>
                    <FormControl as={GridItem} colSpan={[3, 2]}>
                        <FormLabel
                            fontSize="sm"
                            fontWeight="md"
                            color="gray.700"
                            _dark={{
                                color: 'gray.50',
                            }}>
                            Website
                        </FormLabel>
                        <InputGroup size="sm">
                            <InputLeftAddon
                                bg="gray.50"
                                _dark={{
                                    bg: 'gray.800',
                                }}
                                color="gray.500"
                                rounded="md">
                                http://
                            </InputLeftAddon>
                            <Input
                                type="tel"
                                placeholder="www.example.com"
                                focusBorderColor="brand.400"
                                rounded="md"
                            />
                        </InputGroup>
                    </FormControl>

                    <FormControl id="email" mt={1}>
                        <FormLabel
                            fontSize="sm"
                            fontWeight="md"
                            color="gray.700"
                            _dark={{
                                color: 'gray.50',
                            }}>
                            About
                        </FormLabel>
                        <Textarea
                            placeholder="you@example.com"
                            rows={3}
                            shadow="sm"
                            focusBorderColor="brand.400"
                            fontSize={{
                                sm: 'sm',
                            }}
                        />
                        <FormHelperText>
                            Brief description for your profile. URLs are hyperlinked.
                        </FormHelperText>
                    </FormControl>
                </SimpleGrid>
            </>
        )
    }

    
   

    return (
        <Page>
            <Box
                borderWidth="1px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                maxWidth={800}
                p={6}
                m="10px auto"
                as="form">
                <Progress hasStripe value={progress} mb="5%" mx="5%" isAnimated></Progress>
                {step === 1 ? <Form1 /> : step === 2 ? <Form2 /> : <Form3 />}
                <ButtonGroup mt="5%" w="100%">
                    <Flex w="100%" justifyContent="space-between">
                        <Flex>
                            <Button
                                onClick={() => {
                                    setStep(step - 1)
                                    setProgress(progress - 33.33)
                                }}
                                isDisabled={step === 1}
                                colorScheme="teal"
                                variant="solid"
                                w="7rem"
                                mr="5%">
                                Back
                            </Button>
                            <Button
                                w="7rem"
                                isDisabled={step === 3}
                                onClick={() => {
                                    setStep(step + 1)
                                    if (step === 3) {
                                        setProgress(100)
                                    } else {
                                        setProgress(progress + 33.33)
                                    }
                                }}
                                colorScheme="teal"
                                variant="outline">
                                Next
                            </Button>
                        </Flex>
                        {step === 3 ? (
                            <Button
                                w="7rem"
                                colorScheme="red"
                                variant="solid"
                                onClick={() => {
                                    toast({
                                        title: 'Account created.',
                                        description: "We've created your account for you.",
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                }}>
                                Submit
                            </Button>
                        ) : null}
                    </Flex>
                </ButtonGroup>
            </Box>
        </Page>
    )
}

export default Signup



// export default function Multistep() {
//     const toast = useToast()
//     const [step, setStep] = useState(1)
//     const [progress, setProgress] = useState(33.33)

//     return (
//         <>

//         </>
//     )
// }