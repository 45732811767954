import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Icon,
  useBreakpointValue
} from "@chakra-ui/react";
import { ReactElement } from 'react'
import { Link } from "react-router-dom";
import { Page } from "../components/shared/Page";
import {
  Section,
  SectionBody,
  SectionHeadings,
  SectionTag,
  SectionTitle
} from "../components/shared/Section";
import { FcCalendar, FcBullish, FcLock } from 'react-icons/fc'

const features = [
  "Box braids", "Twists", "Bantu Knots"
];


export default function Home() {

  const variants = {
    default: "5xl",
    full: "full",
    prose: "2xl"
  };

  interface FeatureProps {
    title: string
    text: string
    icon: ReactElement
  }

  const Feature = ({ title, text, icon }: FeatureProps) => {
    return (
      <Stack>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={'gray.100'}
          mb={1}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{title}</Text>
        <Text color={'gray.600'}>{text}</Text>
      </Stack>
    )
  }


  return (
    <Page>

      <Flex
        w={'full'}
        h={'70vh'}
        backgroundImage={
          'url(https://images.unsplash.com/photo-1525498128493-380d1990a112?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80)'
        }
        backgroundSize={'cover'}
        borderRadius="20"
        background-attachment="fixed"
        backgroundPosition={'center center'}>
        <VStack
          w={'full'}
          justify={'center'}
          px={useBreakpointValue({ base: 4, md: 8 })}
          borderRadius="20"
          bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
          <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
            <Heading
              letterSpacing="tighter"
              maxWidth="2xl"
              mx="auto"
              fontWeight="extrabold"
              size="3xl"
            >
              <Text
                bgColor="white"
                borderColor='white'
                bgClip='text'
                fontWeight='extrabold'
              >
                You bring the Technique
              </Text>{" "}

            </Heading>
            <Heading
              letterSpacing="tighter"
              maxWidth="2xl"
              mx="auto"
              fontWeight="extrabold"
              size="3xl"
            >
              <Text
                bgGradient='linear(to-l, #2193b0, #6dd5ed)'
                bgClip='text'
                fontWeight='extrabold'
              >
                We bring the Technology!
              </Text>{" "}
            </Heading>
            <Text
              bgColor="white"
              borderColor='white'
              bgClip='text'
              fontSize="1.5rem"
            >
              Join the versetile platform and connect with clients conveniently around your location.
            </Text>{" "}
            <Stack direction={'row'}>
              <Button
                bg={'blue.400'}
                rounded={'full'}
                color={'white'}
                as={Link} colorScheme="blue" size="lg" to="/signup"
                _hover={{ bg: 'blue.500' }}>
                Sign Me Up
              </Button>
            </Stack>
          </Stack>
        </VStack>
      </Flex>

      <Box p={4}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Feature
            icon={<Icon as={FcCalendar} w={10} h={10} />}
            title={'Own time. Own Schedule'}
            text={
              'You are in charge of your schedule. Thrive at your own pace'
            }
          />
          <Feature
            icon={<Icon as={FcBullish} w={10} h={10} />}
            title={'Track your growth'}
            text={
              'Set monthly targets and track your progress'
            }
          />
          <Feature
            icon={<Icon as={FcLock} w={10} h={10} />}
            title={'Safe and Secure'}
            text={
              'Safe and secure platform to help you thrive'
            }
          />
        </SimpleGrid>
      </Box>

      {/*<Section>
        <Heading
          letterSpacing="tighter"
          maxWidth="2xl"
          mx="auto"
          fontWeight="extrabold"
          size="4xl"
        >
          <Text
            bgGradient='linear(to-l, #2193b0, #6dd5ed)'
            bgClip='text'
            fontWeight='extrabold'
          >
            Cuticle
          </Text>{" "}

        </Heading>
        <Box textAlign="center"

          blur='2px'
          backgroundPosition="right"
          backgroundRepeat="no-repeat"
          borderRadius="20" padding="20">
          <Heading
            letterSpacing="tighter"
            maxWidth="2xl"
            mx="auto"
            fontWeight="extrabold"
            size="3xl"
          >
            <Text
              bgGradient='linear(to-l, #2193b0, #6dd5ed)'
              bgClip='text'
              fontWeight='extrabold'
            >
              Hair. Nails. Beauty.
            </Text>{" "}

          </Heading>
          <Text color="black" fontSize="xl" maxWidth="xl" mx="auto" mt="6">
            Bring your skills and we will handle the customers for you.
          </Text>
          <Stack
            direction={{ base: "column", sm: "row" }}
            justify="center"
            mt="10"
            spacing={4}
          >
            {/* <Button as={Link} size="lg" to="/developers">
              Developers
            </Button> */}{/*
            <Button as={Link} colorScheme="blue" size="lg" to="/signin">
              Join the Team
            </Button>

          </Stack>
        </Box>

        <Box textAlign="center"

          blur='2px'
          backgroundPosition="right"
          backgroundRepeat="no-repeat"
          borderRadius="20" padding="20">
          <Heading
            letterSpacing="tighter"
            maxWidth="2xl"
            mx="auto"
            fontWeight="extrabold"
            size="3xl"
          >
            <Text
              bgGradient='linear(to-l, #2193b0, #6dd5ed)'
              bgClip='text'
              fontWeight='extrabold'
            >
              Style them on your terms!
            </Text>{" "}

          </Heading>
          <Text color="black" fontSize="xl" maxWidth="xl" mx="auto" mt="6">
            Do business conviniently
          </Text>
          <Stack
            direction={{ base: "column", sm: "row" }}
            justify="center"
            mt="10"
            spacing={4}
          >
            {/* <Button as={Link} size="lg" to="/developers">
              Developers
            </Button> */}{/*
            <Button as={Link} colorScheme="blue" size="lg" to="/signin">
              Join the Team
            </Button>

          </Stack>
        </Box>*/}

        {/* <SectionHeadings>
          <SectionTitle></SectionTitle>
        </SectionHeadings>

        <SectionHeadings>
          <SectionTag>Styles</SectionTag>
          <SectionTitle>We Offer</SectionTitle>
        </SectionHeadings>
        <SectionBody>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: "5", md: "8" }}>
            {features.map((feature, index) => (
              <Flex key={index} align="center">
                <CheckIcon color={'green.400'} mr="3" />
                <Text color="muted">{feature}</Text>
              </Flex>
            ))}
          </SimpleGrid>
        </SectionBody> 
      {/*</Section>*/}
    </Page>
  );
}
