import { Style } from "../../types/style";
import { Empty } from "../shared/Empty";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { truncate } from "../../utils/functions";

type PostTableProps = {
  onDelete: (style: Style) => void;
  styles?: Style[];
};

const StyleTable = ({ onDelete, styles }: PostTableProps) => {
  if (!styles || styles.length === 0) {
    return <Empty message="Styles will show up here!" />
  }

  return (
    <Box overflowX="auto" >
      <Table variant={'simple'}>
        <Thead>
          <Tr>
            <Th>Hair Style</Th>
            <Th>Variation</Th>
            <Th>Duration</Th>
            <Th>Cost</Th>
            <Th>Category</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {styles.map((item) => (
            <Tr key={item.id}>
              <Td>{item.hairStyle}</Td>
              <Td>{item.variation}</Td>
              <Td>{item.duration}</Td>
              <Td>{item.cost}</Td>
              <Td>{item.Category}</Td>
              {/* <Td>
                {item.published ? (
                  <Badge colorScheme="green">Public</Badge>
                ) : (
                  <Badge>Private</Badge>
                )}
              </Td> */}
              <Td isNumeric>
                <HStack justify="right">
                  <IconButton
                    aria-label={`Edit post ${item.id}`}
                    as={Link}
                    icon={<EditIcon />}
                    to={`/admin/styles/${item.id}`}
                  />
                  <IconButton
                    aria-label={`Delete post ${item.id}`}
                    icon={<DeleteIcon />}
                    onClick={() => onDelete(item)}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export { StyleTable }