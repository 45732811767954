import axios from "axios";
import { supabaseClient } from "../config/supabase-client";
import { Style } from "../types/style";

const styleUrl: string = `${process.env.REACT_APP_BACKEND_URL}/api/v1/styles`;

export async function fetchStyles(): Promise<Style[]> {
  const { data } = await axios.get(styleUrl);
  return data;
}

export async function fetchStylesByProfileId(profileId: number): Promise<Style[]> {
  const { data } = await axios.get(styleUrl + '/stylesByProfileId/' + profileId);
  return data;
}

export async function fetchStyleById(id: number): Promise<Style> {
  const { data } = await axios.get(styleUrl + '/style/' + id);
  return data;
}

export async function addStyle(style: Style): Promise<Style> {
  const { data: { session } } = await supabaseClient.auth.getSession();

  const { data } = await axios.post(styleUrl + '/create', style, {
    headers: { Authorization: `token ${session?.access_token}` }
  });
  return data;
}

export async function updateStyle(style: Style) {
  const { data } = await axios.put(`${styleUrl}/updateById/${style.id}`, style);
  return data;
}

export async function deletePostById(id: number) {
  const { data } = await axios.delete(`${styleUrl}/deleteById/${id}`);
  return data;
}