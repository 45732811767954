import { CheckIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Heading,
    SimpleGrid,
    Stack,
    Text,
    VStack,
    Icon,
    useBreakpointValue,
    FormLabel,
    FormControl,
    Input,
    FormErrorMessage,
    InputLeftElement,
    InputGroup,
    Image,
    Select,
} from "@chakra-ui/react";
import { ReactElement } from 'react'
import { Link } from "react-router-dom";
import { Page } from "../components/shared/Page";
import {
    Section,
    SectionBody,
    SectionHeadings,
    SectionTag,
    SectionTitle
} from "../components/shared/Section";
import { FcCalendar, FcBullish, FcLock } from 'react-icons/fc'

const features = [
    "Box braids", "Twists", "Bantu Knots"
];


export default function ComingSoon() {

    const variants = {
        default: "5xl",
        full: "full",
        prose: "2xl"
    };

    interface FeatureProps {
        title: string
        text: string
        icon: ReactElement
    }

    return (
        // <Page>
        <Flex
            direction="column"
            align="center"
            justify="center"
            minH="100vh"
            bgGradient="linear(to-r, teal.300, green.400)"
            color="white"
            px={4}
        >
            <VStack spacing={6} textAlign="center">

                <Heading as="h1" size="3xl">
                    Z e n Z u r i
                </Heading>
                <Text fontSize="2xl">Your Beauty, Your Time.</Text>
                <Text fontSize="lg" maxW="600px">
                    We're launching soon! Get ready to indulge in luxury at your
                    convenience. Beauty and body care services like hair, nails, and
                    massages delivered to your door step.
                </Text>
                {/* <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                    <Input
                        placeholder="Enter your email"
                        variant="filled"
                        size="lg"
                        borderRadius="md"
                    />
                    <Button
                        colorScheme="teal"
                        size="lg"
                        borderRadius="md"
                        onClick={() => alert("Subscribed!")}
                    >
                        Notify Me
                    </Button>
                </Stack> */}
                <Text fontSize="sm">Stay tuned for our launch date!</Text>
            </VStack>
        </Flex>
        // </Page>
    );
}
