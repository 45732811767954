import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Link as ChakraLink,
  Stack,
  Box,
  Image,
  Text
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { Page } from "../../components/shared/Page";
import {
  Toolbar,
  ToolbarActions,
  ToolbarTitle
} from "../../components/shared/Toolbar";
import { Loading } from "../../components/shared/Loading";
import { useStyle } from "../../hooks/style/useStyle";
import StyleForm from "../../components/style/StyleForm";
import ImageUpload from "../../components/shared/ImageUpload";
import { supabaseClient } from '../../config/supabase-client';
import { useUpdateStyle } from "../../hooks/style/useUpdateStyle";
import { Style as StyleType } from "../../types/style"; // Adjust the import path as necessary
import React, { useState, useEffect } from 'react';

export const Style = () => {
  const { id } = useParams();
  const { data, isError, isLoading } = useStyle(Number(id));
  const updateStyle = useUpdateStyle()
  const isUpdate = !!id;

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [refresh, setRefresh] = useState(false);

  async function downloadImage(path: any) {
    try {
      const { data, error }: any = await supabaseClient
        .storage
        .from('images')
        .download(path);

      if (error) {
        throw error;
      }
      console.log(data)
      setImageUrl(URL.createObjectURL(data));
      console.log(imageUrl)
    } catch (error: any) {
      console.log('Error downloading image: ', error.message);
    }
  }

  useEffect(() => {
    if (data?.imageUrl) {
      downloadImage(data.imageUrl);
    }
  }, [data, refresh]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <span>Something went wrong! If the problem persists, contact us!</span>
    );
  }
  const uploadImage = async (file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);

    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${fileName}`;

    let { error: uploadError } = await supabaseClient.storage.from('images').upload(filePath, file);
    
    if (uploadError) {
      console.log(uploadError);
      return;
    }

    updateStyle.mutate({ ...data, imageUrl: filePath } as StyleType, {
      onSuccess: () => {
        if (data) {
          toast({
            description: `${data.hairStyle} ${data.variation} has been updated`,
            status: "success"
          });
          setRefresh(prev => !prev); // Trigger a refresh
        }
      },
    })

  };

  return (
    <Page animation="slideFade">
      <Toolbar>
        <ToolbarTitle>
          {isUpdate ? `Update style ${data?.hairStyle}` : "Add style"}
        </ToolbarTitle>
        <ToolbarActions>
          <Button
            as={Link}
            leftIcon={<ArrowBackIcon />}
            to="/admin/styles"
            variant="outline"
          >
            Back
          </Button>
        </ToolbarActions>
      </Toolbar>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(3, 1fr)" }}
        gap={6}
        mt="8"
      >
        <GridItem colSpan={{ xl: 2 }}>
          <Card>
            <CardHeader>
              <Text>Style form</Text>
            </CardHeader>
            <CardBody>
              <StyleForm initialValues={data} />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem>
          <Card>
            <CardHeader>
              <Text>Images</Text>
            </CardHeader>
            <CardBody>

              {imageUrl ? (
                <Image boxSize='200px' src={imageUrl} />
              ) : (
                <ImageUpload uploadImage={uploadImage} />
              )}
              
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Page>
  )
}

function toast(arg0: { description: string; status: string; }) {
  throw new Error("Function not implemented.");
}
