import { OptionBase } from "chakra-react-select";

export interface Gender extends OptionBase {
    label: string;
    value: string;
}

export const gender: Gender[] = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
];