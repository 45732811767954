import { useMutation, useQueryClient } from "react-query";
import { updateStyle } from "../../api/styleApi";

export function useUpdateStyle() {
  const queryClient = useQueryClient();

  return useMutation(updateStyle, {
    onSuccess: () => {
      queryClient.invalidateQueries("styles");
    }
  });
}
