import React, { useState, ChangeEvent, FormEvent } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  useToast,
} from '@chakra-ui/react';

interface ImageUploadProps {
  uploadImage: (file: File) => Promise<void>;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ uploadImage }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewSource, setPreviewSource] = useState<string>('');
  const toast = useToast();

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      previewFile(file);
      setSelectedFile(file);
    }
  };

  const previewFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result as string);
    };
  };

  const handleSubmitFile = async (e: FormEvent) => {
    e.preventDefault();
    console.log(selectedFile);
    if (!selectedFile) return;
    try {
      await uploadImage(selectedFile);
      toast({
        title: 'File uploaded.',
        description: 'Your file has been uploaded successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Upload failed.',
        description: 'There was an error uploading your file.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      maxW="sm"
      mx="auto"
      mt={8}
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <form onSubmit={handleSubmitFile}>
        <FormControl>
          <FormLabel htmlFor="file">Upload Image</FormLabel>
          <Input
            type="file"
            id="file"
            accept="image/*" multiple
            onChange={handleFileInputChange}
            mb={4}
          />
        </FormControl>
        {previewSource && (
          <Box mb={4}>
            <Image
              src={previewSource}
              alt="chosen"
              boxSize="200px"
              objectFit="cover"
            />
          </Box>
        )}
        <Button type="submit" colorScheme="teal">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default ImageUpload;