import { useMutation, useQueryClient } from "react-query";
import { addStyle } from "../../api/styleApi";

export function useAddStyle() {
  const queryClient = useQueryClient();

  return useMutation(addStyle, {
    onSuccess: () => {
      queryClient.invalidateQueries("styles");
    }
  });
}