import { CheckIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Heading,
    SimpleGrid,
    Stack,
    Text,
    VStack,
    Icon,
    useBreakpointValue,
    FormLabel,
    FormControl,
    Input,
    FormErrorMessage,
    InputLeftElement,
    InputGroup,
    Image,
    Select,
} from "@chakra-ui/react";
import { ReactElement } from 'react'
import { Link } from "react-router-dom";
import { Page } from "../components/shared/Page";
import {
    Section,
    SectionBody,
    SectionHeadings,
    SectionTag,
    SectionTitle
} from "../components/shared/Section";
import { FcCalendar, FcBullish, FcLock } from 'react-icons/fc'

const features = [
    "Box braids", "Twists", "Bantu Knots"
];


export default function Landing() {

    const variants = {
        default: "5xl",
        full: "full",
        prose: "2xl"
    };

    interface FeatureProps {
        title: string
        text: string
        icon: ReactElement
    }

    const Feature = ({ title, text, icon }: FeatureProps) => {
        return (
            <Stack>
                <Flex
                    w={16}
                    h={16}
                    align={'center'}
                    justify={'center'}
                    color={'white'}
                    rounded={'full'}
                    bg={'gray.100'}
                    mb={1}>
                    {icon}
                </Flex>
                <Text fontWeight={600}>{title}</Text>
                <Text color={'gray.600'}>{text}</Text>
            </Stack>
        )
    }


    return (
        <Page>

            <SimpleGrid
                columns={{
                    base: 1,
                    md: 2,
                }}
                spacing={0}
            >
                <Flex bg="brand.400">
                    <Image
                        src="https://images.unsplash.com/photo-1592328906746-0a3ca0bde253?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="woman with braided hair"
                        fit="cover"
                        w="full"
                        h={{
                            base: 32,
                            md: "lg",
                        }}
                        bg="gray.100"
                        loading="lazy"
                        opacity={0.6}
                    />
                </Flex>
                <Flex
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    px={{
                        base: 4,
                        md: 8,
                        lg: 20,
                    }}
                    py={24}
                    zIndex={3}
                >
                    <Stack
                        color="brand.600"
                        _dark={{
                            color: "gray.300",
                        }}
                        fontSize="lg"
                        textTransform="uppercase"
                        fontWeight="extrabold"
                    >
                        
                    </Stack>
                    <Heading
                        mb={4}
                        fontSize={{
                            base: "4xl",
                            md: "4xl",
                            lg: "5xl",
                        }}
                        fontWeight="bold"
                        color="brand.600"
                        _dark={{
                            color: "gray.300",
                        }}
                        lineHeight="shorter"
                        textShadow="2px 0 currentcolor"
                    >
                        Own your Zen and be Zuri!
                    </Heading>
                    <Text
                        pr={{
                            base: 0,
                            lg: 16,
                        }}
                        mb={4}
                        fontSize="lg"
                        color="brand.600"
                        _dark={{
                            color: "gray.400",
                        }}
                        letterSpacing="wider"
                    >
                        From our vetted stylists for any occassion.
                    </Text>
                    <Stack display="inline-flex" rounded="md">

                    </Stack>
                </Flex>
            </SimpleGrid>



            {/* <Flex
  bg="#edf3f8"
  _dark={{
    bg: "#3e3e3e",
  }}
  p={50}
  w="full"
  alignItems="center"
  justifyContent="center"
> */}
            <Box
                bg="gray.50"
                _dark={{
                    bg: "gray.800",
                }}
            >
                <Box
                    maxW="7xl"
                    w={{
                        md: "3xl",
                        lg: "4xl",
                    }}
                    mx="auto"
                    py={{
                        base: 12,
                        lg: 16,
                    }}
                    px={{
                        base: 4,
                        lg: 8,
                    }}
                    display={{
                        lg: "flex",
                    }}
                    alignItems={{
                        lg: "center",
                    }}
                    justifyContent={{
                        lg: "space-between",
                    }}
                >
                    <Heading
                        fontSize={{
                            base: "3xl",
                            sm: "4xl",
                        }}
                        fontWeight="extrabold"
                        letterSpacing="tight"
                        lineHeight="shorter"
                        color="gray.900"
                        _dark={{
                            color: "gray.100",
                        }}
                    >
                        <Box display="block">Ready to dive in?</Box>
                        <Box
                            display="block"
                            color="brand.600"
                            _dark={{
                                color: "gray.500",
                            }}
                        >
                            Beauty near you! Now!.
                        </Box>
                    </Heading>
                    <Stack
                        direction={{
                            base: "column",
                            sm: "row",
                        }}
                        mt={{
                            base: 8,
                            lg: 0,
                        }}
                        flexShrink={{
                            lg: 0,
                        }}
                    >
                        <Stack direction={'row'}>
                            <Button
                                bg={'blue.400'}
                                rounded={'full'}
                                color={'white'}
                                as={Link} colorScheme="blue" size="lg" to="/signup"
                                _hover={{ bg: 'blue.500' }}>
                                Sign Me Up
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>

            {/* <Flex
  bg="#edf3f8"
  _dark={{
    bg: "#3e3e3e",
  }}
  p={20}
  w="full"
  justifyContent="center"
  alignItems="center"
  pos="absolute"
> */}
            <Box
                shadow="xl"
                bg="white"
                _dark={{
                    bg: "gray.800",
                }}
                px={8}
                py={20}
                mx="auto"
            >
                <SimpleGrid
                    alignItems="start"
                    columns={{
                        base: 1,
                        md: 2,
                    }}
                    mb={24}
                    spacingY={{
                        base: 10,
                        md: 32,
                    }}
                    spacingX={{
                        base: 10,
                        md: 24,
                    }}
                >
                    <Box>
                        <Heading
                            mb={4}
                            fontSize={{
                                base: "2xl",
                                md: "4xl",
                            }}
                            fontWeight="extrabold"
                            letterSpacing="tight"
                            textAlign={{
                                base: "center",
                                md: "left",
                            }}
                            color="gray.900"
                            _dark={{
                                color: "gray.400",
                            }}
                            lineHeight={{
                                md: "shorter",
                            }}
                            textShadow="2px 0 currentcolor"
                        >
                            Clear overview for efficient tracking
                        </Heading>
                        <Text
                            mb={5}
                            textAlign={{
                                base: "center",
                                sm: "left",
                            }}
                            color="gray.600"
                            _dark={{
                                color: "gray.400",
                            }}
                            fontSize={{
                                md: "lg",
                            }}
                        >
                            Handle your subscriptions and transactions efficiently with the clear
                            overview in Dashboard. Features like the smart search option allow you
                            to quickly find any data you’re looking for.
                        </Text>
                        <Button
                            w={{
                                base: "full",
                                sm: "auto",
                            }}
                            size="lg"
                            bg="gray.900"
                            _dark={{
                                bg: "gray.700",
                            }}
                            _hover={{
                                bg: "gray.700",
                                _dark: {
                                    bg: "gray.600",
                                },
                            }}
                            color="gray.100"
                            as="a"
                        >
                            Learn More
                        </Button>
                    </Box>
                    <Box
                        w="full"
                        h="full"
                        py={48}
                        bg="gray.200"
                        _dark={{
                            bg: "gray.700",
                        }}
                    ></Box>
                </SimpleGrid>
                <SimpleGrid
                    alignItems="center"
                    columns={{
                        base: 1,
                        md: 2,
                    }}
                    flexDirection="column-reverse"
                    mb={24}
                    spacingY={{
                        base: 10,
                        md: 32,
                    }}
                    spacingX={{
                        base: 10,
                        md: 24,
                    }}
                >
                    <Box
                        order={{
                            base: "initial",
                            md: 2,
                        }}
                    >
                        <Heading
                            mb={4}
                            fontSize={{
                                base: "2xl",
                                md: "4xl",
                            }}
                            fontWeight="extrabold"
                            letterSpacing="tight"
                            textAlign={{
                                base: "center",
                                md: "left",
                            }}
                            color="gray.900"
                            _dark={{
                                color: "gray.400",
                            }}
                            lineHeight={{
                                md: "shorter",
                            }}
                        >
                            Decide how you integrate Payments
                        </Heading>
                        <Text
                            mb={5}
                            textAlign={{
                                base: "center",
                                sm: "left",
                            }}
                            color="gray.600"
                            _dark={{
                                color: "gray.400",
                            }}
                            fontSize={{
                                md: "lg",
                            }}
                        >
                            Love to code? Next to our ready-made and free plugins you can use our
                            expansive yet simple API; decide how you integrate Payments and build
                            advanced and reliable products yourself from scratch.
                        </Text>
                        <Button
                            w={{
                                base: "full",
                                sm: "auto",
                            }}
                            size="lg"
                            bg="gray.900"
                            _dark={{
                                bg: "gray.700",
                            }}
                            _hover={{
                                bg: "gray.700",
                                _dark: {
                                    bg: "gray.600",
                                },
                            }}
                            color="gray.100"
                            as="a"
                        >
                            Learn More
                        </Button>
                    </Box>
                    <Box
                        w="full"
                        h="full"
                        py={48}
                        bg="gray.200"
                        _dark={{
                            bg: "gray.700",
                        }}
                    ></Box>
                </SimpleGrid>
            </Box>
            {/* </Flex>; */}

            {/* </Flex>; */}




            {/*<Section>
        <Heading
          letterSpacing="tighter"
          maxWidth="2xl"
          mx="auto"
          fontWeight="extrabold"
          size="4xl"
        >
          <Text
            bgGradient='linear(to-l, #2193b0, #6dd5ed)'
            bgClip='text'
            fontWeight='extrabold'
          >
            Cuticle
          </Text>{" "}

        </Heading>
        <Box textAlign="center"

          blur='2px'
          backgroundPosition="right"
          backgroundRepeat="no-repeat"
          borderRadius="20" padding="20">
          <Heading
            letterSpacing="tighter"
            maxWidth="2xl"
            mx="auto"
            fontWeight="extrabold"
            size="3xl"
          >
            <Text
              bgGradient='linear(to-l, #2193b0, #6dd5ed)'
              bgClip='text'
              fontWeight='extrabold'
            >
              Hair. Nails. Beauty.
            </Text>{" "}

          </Heading>
          <Text color="black" fontSize="xl" maxWidth="xl" mx="auto" mt="6">
            Bring your skills and we will handle the customers for you.
          </Text>
          <Stack
            direction={{ base: "column", sm: "row" }}
            justify="center"
            mt="10"
            spacing={4}
          >
            {/* <Button as={Link} size="lg" to="/developers">
              Developers
            </Button> */}{/*
            <Button as={Link} colorScheme="blue" size="lg" to="/signin">
              Join the Team
            </Button>

          </Stack>
        </Box>

        <Box textAlign="center"

          blur='2px'
          backgroundPosition="right"
          backgroundRepeat="no-repeat"
          borderRadius="20" padding="20">
          <Heading
            letterSpacing="tighter"
            maxWidth="2xl"
            mx="auto"
            fontWeight="extrabold"
            size="3xl"
          >
            <Text
              bgGradient='linear(to-l, #2193b0, #6dd5ed)'
              bgClip='text'
              fontWeight='extrabold'
            >
              Style them on your terms!
            </Text>{" "}

          </Heading>
          <Text color="black" fontSize="xl" maxWidth="xl" mx="auto" mt="6">
            Do business conviniently
          </Text>
          <Stack
            direction={{ base: "column", sm: "row" }}
            justify="center"
            mt="10"
            spacing={4}
          >
            {/* <Button as={Link} size="lg" to="/developers">
              Developers
            </Button> */}{/*
            <Button as={Link} colorScheme="blue" size="lg" to="/signin">
              Join the Team
            </Button>

          </Stack>
        </Box>*/}

            {/* <SectionHeadings>
          <SectionTitle></SectionTitle>
        </SectionHeadings>

        <SectionHeadings>
          <SectionTag>Styles</SectionTag>
          <SectionTitle>We Offer</SectionTitle>
        </SectionHeadings>
        <SectionBody>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: "5", md: "8" }}>
            {features.map((feature, index) => (
              <Flex key={index} align="center">
                <CheckIcon color={'green.400'} mr="3" />
                <Text color="muted">{feature}</Text>
              </Flex>
            ))}
          </SimpleGrid>
        </SectionBody> 
      {/*</Section>*/}
        </Page>
    );
}
