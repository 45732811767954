import { Box, Button, InputGroup, Flex, FormControl, FormErrorMessage,
     FormLabel, Heading, Input, Select, Stack, Textarea, useToast,
      InputLeftElement } from "@chakra-ui/react"
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useProfile } from "../../hooks/auth/useProfile";
import { useAddStyle } from "../../hooks/style/useAddStyle";
import { useUpdateStyle } from "../../hooks/style/useUpdateStyle";

//import { useUpdatePost } from "../../hooks/post/useUpdatePost";
import { useAuth } from "../../providers/AuthProvider";
import { Style } from "../../types/style";
//import { PostType, POST_TYPE_OPTIONS } from "../../utils/constants";
import { FormHeader } from "../shared/Form"
import { Page } from "../shared/Page"
import { useNavigate } from "react-router-dom";
import { Tag, tags } from "../../types/tag";
import { Select as MultiSelect } from "chakra-react-select";
//import useStyles from "../../hooks/style/useStyles";

type FormValues = {
    id: number;
    hairStyle: string;
    variation: string;
    duration: string;
    cost: number;
    Category: string;
};

type StyleFormProps = {
    initialValues?: Style;
};

const StyleForm = ({ initialValues }: StyleFormProps) => {
    const navigate = useNavigate();
    const toast = useToast();
    const addStyle = useAddStyle();
    const updateStyle = useUpdateStyle()
    let { session } = useAuth();
    const { data: profile } = useProfile(session?.user.email);

    const isUpdate = !!initialValues;
    const isProcessing = addStyle.isLoading || updateStyle.isLoading;

    const {
        handleSubmit,
        register,
        setValue,
        reset,
        control,
        formState: { errors }
    } = useForm<FormValues>();

    useEffect(() => {
        //if (profile) setValue('profileId', profile.id)
        if (initialValues) reset(initialValues)
    }, [profile, setValue, initialValues, reset]);

    const handleAddPost: SubmitHandler<FormValues> = (style) => {
        addStyle.mutate(style as Style, {
            onSuccess: () => {
                toast({
                    description: `style has been added`,
                    status: "success"
                });
                navigate("/admin/posts");
                //socket.emit("createPost", { post, username: profile?.username });
            },
            onError: () => {
                toast({
                    description:
                        "Something went wrong! If the problem persists, contact us!",
                    status: "error"
                });
            }
        });
    };

    const handleUpdatePost: SubmitHandler<FormValues> = (style) => {
        updateStyle.mutate(style as Style, {
            onSuccess: () => {
                toast({
                    description: `${style.hairStyle} ${style.variation} has been updated`,
                    status: "success"
                });
            },
            onError: () => {
                toast({
                    description:
                        "Something went wrong! If the problem persists, contact us!",
                    status: "error"
                });
            }
        });
    };

    const onSubmit: SubmitHandler<FormValues> = (values) => {
        if (isUpdate) {
            handleUpdatePost(values);
        } else {
            handleAddPost(values);
        }
    };

    return (
        <Page animation="slideFade">
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={5}>
                    <FormHeader>
                        <Heading fontSize="lg" fontWeight="semibold">
                            Add Style
                        </Heading>
                    </FormHeader>
                    <FormControl isInvalid={!!errors.hairStyle}>
                        <FormLabel htmlFor="hairStyle">Style</FormLabel>
                        <Input
                            id="hairStyle"
                            placeholder="Style"
                            {...register("hairStyle", {
                                required: "This is required"
                            })}
                        />
                        <FormErrorMessage>
                            {errors.hairStyle && errors.hairStyle.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.variation}>
                        <FormLabel htmlFor="variation">Variation</FormLabel>
                        <Input
                            id="variation"
                            placeholder='variation'
                            {...register("variation", {
                                required: "This is required"
                            })} />
                        <FormErrorMessage>
                            {errors.variation && errors.variation.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="duration">Duration</FormLabel>
                        <Input
                            id="duration"
                            placeholder='Duration'
                            {...register("duration", {
                                required: "This is required"
                            })} />
                        <FormErrorMessage>
                            {errors.duration && errors.duration.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="cost">Cost</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
                                R
                            </InputLeftElement>
                            <Input
                            id="cost"
                            placeholder='cost'
                            {...register("cost", {
                                required: "This is required"
                            })} />
                            
                        </InputGroup>
                        
                        <FormErrorMessage>
                            {errors.cost && errors.cost.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="category">Category</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
                                R
                            </InputLeftElement>
                            <Input
                            id="Category"
                            placeholder='Category'
                            {...register("Category", {
                                required: "This is required"
                            })} />
                            
                        </InputGroup>
                        
                        <FormErrorMessage>
                            {errors.Category && errors.Category.message}
                        </FormErrorMessage>
                    </FormControl>
                    {/* <FormControl>
                        <FormLabel htmlFor="type">Type</FormLabel>
                        <Select placeholder='type' {...register("type")}>
                            {POST_TYPE_OPTIONS.map(({ value, label }) => (
                                <option value={value} key={value}>
                                    {label}
                                </option>
                            ))}
                        </Select>
                    </FormControl> */}
                    {/* <Controller
                        control={control}
                        name="tags"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error }
                        }) => (
                            <FormControl py={4} isInvalid={!!error} id='tags'>
                                <FormLabel>Post tags</FormLabel>
                                <MultiSelect
                                    isMulti
                                    name={name}
                                    ref={ref}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    options={tags}
                                    placeholder="Pick post tags"
                                    closeMenuOnSelect={false}
                                />
                                <FormErrorMessage>{error && error.message}</FormErrorMessage>
                            </FormControl>
                        )}
                    /> */}
                    <Flex direction="row-reverse">
                        <Button colorScheme="blue" type="submit" isLoading={isProcessing}>
                            Save
                        </Button>
                    </Flex>
                </Stack>
            </Box>
        </Page>
    )
}

export default StyleForm